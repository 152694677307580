.Navbar-container {
  .navbar {
    background-color: #ffffff;
    padding: 12px 35px;
    box-shadow: 0px 0px 18px #0000001a;
    height: 75px;
    position: fixed;
    left: 0px;
    width: 100%;
    top: 0px;
    z-index: 999;
  }

  .ugro-logo {
    width: 115px;
    height: auto;
  }

  .submit-btn {
    padding: 20px 0px;
    text-align: center;
  }

  .btn-view {
    border-radius: 7px;
    width: 96px;
    height: 39px;
    background-color: #e35626;
    color: #ffffff;
    line-height: 18px;
    border: none;
    font-size: 15px;
    margin-left: 8px;
    font-family: "Mulish-SemiBold";
    box-shadow: 0px 3px 6px #00000029;
    cursor: pointer;
  }

  .appid {
    border-radius: 7px;
    width: 147px;
    height: 39px;
    background-color: #f5f5f5;
    color: #000000;
    line-height: 18px;
    border: none;
    font-size: 15px;
    text-align: center;
    justify-content: center;
    display: flex;
    align-items: center;
    font-family: "Mulish-SemiBold";
    box-shadow: 0px 3px 6px #00000029;
    margin-right: 12px;
    cursor: pointer;
  }

  .App_id {
    color: #000000;
    font-family: "Mulish-SemiBold";
    font-size: 12px;
    margin-left: 2px;
    padding-top: 1px;
  }

  .sub-header {
    background-color: #e35626;
    width: 100%;
    height: 90px;
    margin-top: 75px;
  }

  .login-heading p {
    font-size: 18px;
    font-family: 'Mulish-SemiBold';
    line-height: 24px;
    // padding-bottom: 4px;
    color: #000000;
  }

  .login-heading {

    width: 100%;

  }

  .login-heading p span {
    color: #ff4100;
  }

  @media (max-width: 600px) {
    .navbar {
      padding: 12px 20px;
      display: flex;
      flex-wrap: nowrap;
    }

    .App_id {
      color: #000000;
      font-family: "Mulish-SemiBold";
      font-size: 12px;
      margin-left: 2px;
      padding-top: 1px;
    }

    .login-heading p {
      font-size: 16px;
      font-family: "Mulish-SemiBold";
      line-height: 20px;
      padding-left: 40px;
      color: #000000;
    }

    .login-heading {
      width: 100%;
    }

    .login-heading p span {
      color: #ff4100;
    }

    .ugro-logo {
      width: 80px;
      height: auto;
    }

    .submit-btn {
      padding: 20px 0px;
      text-align: center;
    }

    .btn-view {
      border-radius: 7px;
      width: 75px;

      font-size: 13px !important;
      margin-left: 8px;
    }

    .appid {
      border-radius: 7px;
      width: 135px;
      font-size: 13px;
      margin-right: 5px;
    }
  }
}
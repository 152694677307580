.basicBusinessInformation-container {
  .Login_View {
    width: 100%;
    min-height: 88vh;
    background-color: #ffffff;
  }

  .body-container {
    padding: 15px 0px;
  }

  .top-header {
    margin-bottom: 10px;
  }

  .top-header-icon {
    margin-right: 20px;
  }

  .top-header-icon img {
    width: 40px;
    height: auto;
  }

  .top-header-content h2 {
    font-size: 20px;
    color: #000000;
    line-height: 25px;
    font-family: "Mulish-SemiBold";
  }


  .business-entity h3 {
    font-size: 16px;
    color: #000000;
    line-height: 18px;
    font-family: "Mulish-SemiBold";
    padding-top: 6px;
    padding-bottom: 8px;
  }

  .radio-container {
    margin: 5px 0px;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #f4f4f4;
    background-color: #f4f4f4;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
    font-family: "Mulish-Light";
    padding: 5px 12px;
    height: 45px;
  }

  .radio-container input {
    margin-right: 10px;
    accent-color: #ff4100;
  }

  .radio-container input:checked ~ label {
    color: #ff4100;
  }

  .radio-container label {
    font-size: 12px;
    color: #9b9b9b;
    line-height: 15px;
    font-family: "Mulish-Regular";
  }

  .form-input {
    padding-bottom: 15px;
    width: 100%;
  }

  .form-input label {
    font-size: 16px;
    color: #000000;
    line-height: 28px;
    font-family: "Mulish-SemiBold";
  }

  .form-input select {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #f4f4f4;
    background-color: #ffffff;
    // padding: 10px 12px;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    display: block;
    font-family: "Mulish-Light";
  }

  .form-input select:focus {
    outline: none !important;
    box-shadow: none;
  }

  .form-input select:focus-visible {
    outline: none;
  }

  .file > input[type="file"] {
    display: none;
  }

  .file > label {
    font-size: 1rem;
    font-weight: 300;
    cursor: pointer;
    outline: 0;
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 5px;
    border: 1px dashed #707070;
    background-color: #ffffff;
    padding: 10px 12px;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    display: block;
    font-family: "Mulish-Light";
  }

  .Clip-img {
    width: 18px;
    height: auto;
    transform: rotatey(180deg);
  }

  .submit-btn {
    padding: 20px 0px;
    text-align: center;
  }

  .btn-view {
    border-radius: 5px;
    width: 150px;
    height: 40px;
    background-color: #e35626;
    color: #ffffff;
    line-height: 18px;
    border: none;
    font-size: 14px;
  }
  @media(max-width:600px){
    .radio-container {
      height: 90px;
  }

  .content-container {
    padding: 25px 0px!important;
}
  }
}

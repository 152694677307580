.LoanReject-container {
    .LoanReject_View {
        width: 100%;
        min-height: 86vh;
        height: 86vh;
        background-color: #FFFFFF;
        position: relative;
    }
    
    
    .LoanReject_body {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 80%;
        padding-left: 18px;
        padding-right: 18px;
        flex-direction: column;
    }

    .reject-img{
        width: 88px;
        height: auto;
        margin-bottom: 20px;
        margin-top: 20px;
    }

    .LoanReject_body h1 {
        font-size: 14px;
        font-family: 'Mulish-SemiBold';
        line-height: 18px;
        padding-bottom: 10px;
        color: #000000;
    }

    .LoanReject_body p {
        font-size: 14px;
        font-family: 'Mulish-Light';
        line-height: 18px;
        color: #000000;
        text-align: center;
    }

    .close-icon-top{
        position: absolute;
        top: 8px;
        right: 12px;
    }

    .crossimg-img{
        width: 24px;
        height: auto;
        cursor: pointer;
    }

    h1.rj-heading {
        font-size: 20px;
        font-family: 'Mulish-SemiBold';
        padding: 15px 0px;
    }

    p.rj-text {
        font-size: 16px;
        font-family: 'Mulish-Regular';
    }
}
.ProcessCompleted-container {
    .ProcessCompleted_View {
        width: 100%;
        min-height: 86vh;
        height: 86vh;
        background-color: #FFFFFF;
        position: relative;
    }
   
    .ProcessCompleted_body {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 80%;
        padding-left: 15px;
        padding-right: 15px;
        flex-direction: column;
    }

    .reject-img{
        width: 90px;
        height: auto;
        margin-bottom: 20px;
    }

    .ProcessCompleted_body h1 {
        font-size: 14px;
        font-family: 'Mulish-SemiBold';
        line-height: 18px;
        padding-bottom: 10px;
        color: #000000;
    }

    .ProcessCompleted_body p {
        font-size: 14px;
        font-family: 'Mulish-Light';
        line-height: 18px;
        color: #000000;
    }
}
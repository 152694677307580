.udhyam_verify-container {

    body {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    .udhyam_verify_View {
        width: 100%;
        min-height: 100vh;
        background-color: #FFFFFF;
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .udhyam_verify_body {
        padding-left: 18px;
        padding-right: 18px;
        margin-top: 22px;
    }
    .udhyam_verify-icon
    {
        text-align: center;
    }

    .udhyam_verify-icon img {
        width: 100px;
        height: auto;
    }
}
.SignedSuccessfully-container {
    .SignedSuccessfully_View {
        width: 100%;
        min-height: 86vh;
        height: 86vh;
        background-color: #FFFFFF;
        position: relative;
    }
   
    .SignedSuccessfully_body {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 80%;
        padding-left: 15px;
        padding-right: 15px;
        flex-direction: column;
    }

    .completed-img{
        width: 120px;
        height: auto;
        margin-bottom: 35px;
    }

    .SignedSuccessfully_body h1 {
        font-size: 14px;
        font-family: 'Mulish-SemiBold';
        line-height: 18px;
        padding-bottom: 10px;
        color: #000000;
    }

    .SignedSuccessfully_body p {
        font-size: 14px;
        font-family: 'Mulish-Light';
        line-height: 18px;
        color: #000000;
    }
    .close_btn_bottom{
        margin-top: 19px;
        font-size: 14px;
        font-family: 'Mulish-SemiBold';
        line-height: 18px;
        color: #FF4100;
        text-decoration: underline;
    }
    .close-icon-top{
        position: absolute;
        top: -34px;
        right: 12px;
    }
    .crossimg-img{
        width: 24px;
        height: auto;
        cursor: pointer;
    }
    .submit-btn {
        margin: 20px 0px;
        text-align: center;
    }

    .btn-view {
        border-radius: 5px;
        width: 107px;
        height: 30px;
        background-color: #E35626;
        color: #FFFFFF;
        line-height: 18px;
        border: none;
        font-size: 14px;
    }
}
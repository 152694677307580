@font-face {
  font-family: 'Mulish-Bold';
  src: url('./assets/font-family/mulish/Mulish-Bold.woff2') format('woff2'),
    url('./assets/font-family/mulish/Mulish-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mulish-ExtraLight';
  src: url('./assets/font-family/mulish/Mulish-ExtraLight.woff2') format('woff2'),
    url('./assets/font-family/mulish/Mulish-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mulish-Regular';
  src: url('./assets/font-family/mulish/Mulish-Regular.woff2') format('woff2'),
    url('./assets/font-family/mulish/Mulish-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mulish-ExtraBold';
  src: url('./assets/font-family/mulish/Mulish-ExtraBold.woff2') format('woff2'),
    url('./assets/font-family/mulish/Mulish-ExtraBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mulish-Light';
  src: url('./assets/font-family/mulish/Mulish-Light.woff2') format('woff2'),
    url('./assets/font-family/mulish/Mulish-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mulish-SemiBold';
  src: url('./assets/font-family/mulish/Mulish-SemiBold.woff2') format('woff2'),
    url('./assets/font-family/mulish/Mulish-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FFFFFF;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p,
h1,
h2,
h3,
h4,
h5,
h6{
  margin: 0px !important;
  padding: 0px;
}

body{
  font-size: 62.5%;
}

.form-input {
  padding-bottom: 15px;
  width: 100%;
}

.form-input label {
  font-size: 16px;
  color: #000000;
  line-height: 28px;
  font-family: 'Mulish-SemiBold';
}

.form-input input {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #F4F4F4;
  background-color: #FFFFFF;
  padding: 10px 12px;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  display: block;
  font-family: 'Mulish-Light';
}
.form-input input:focus {
  outline: none;
}

button:disabled,
button[disabled]{
  background-color: #cccccc !important;
  color: #666666 !important;
}

.input-disabled{
  background-color: #ececec !important;
  color: #666666 !important;
}

.rnc__notification-title {
  color: #fff;
  font-size: 13px !important;
  font-weight: 600 !important;
  margin-bottom: 5px;
  margin-top: 5px;
  line-height: 16px !important;
}
.rnc__notification-close-mark{
  right: 0px !important;
    top: 10px !important;
}

.required_field{
  color: red;
}

.c-pointer{
  cursor: pointer;
}
.US-container {
  .us-body {
    width: 355px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding: 24px;
  }
  .us-body p{
    font-size: 14px;
    color: #000000;
    line-height: 20px;
    font-family: "Mulish-SemiBold";
  }
}

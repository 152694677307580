.aadharverification-container {
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  .address_View {
    width: 100%;
    min-height: 88vh;
    background-color: #ffffff;
    position: relative;
  }

  .address_body {
    padding-left: 18px;
    padding-right: 18px;
    margin-top: 22px;
  }

  .top-header {
    margin-bottom: 10px;
  }

  .top-header-icon {
    margin-right: 20px;
  }

  .top-header-icon img {
    width: 40px;
    height: auto;
  }

  .top-header-content h2 {
    font-size: 20px;
    color: #000000;
    line-height: 25px;
    font-family: "Mulish-SemiBold";
  }

  .form-input label {
    font-size: 16px;
    color: #000000;
    line-height: 28px;
    font-family: "Mulish-SemiBold";
  }

  .text-fatch {
    font-size: 14px;
    width: 100%;
    border-radius: 5px;
    border: none;
    border: 1px solid #dedede;
    padding: 12px 10px;
    font-family: "Mulish-Light";
    height: 45px;
  }

  .radio-container {
    margin-bottom: 10px;
  }

  .radio-container input {
    margin-right: 10px;
    accent-color: #ff4100;
  }

  .radio-container label {
    font-size: 14px;
    color: #000000;
    line-height: 15px;
    font-family: "Mulish-Light";
    margin-bottom: 0px;
  }
  .file > label {
    font-size: 1rem;
    font-weight: 300;
    cursor: pointer;
    background-color: #ffffff;
    padding: 10px 12px;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    display: block;
    font-family: "Mulish-Light";
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
    border-radius: 5px;
    width: 100%;
    height: auto;
    border: 1px dashed #707070;
  }
  .icon_img {
    background-color: #ff4100;
    border-radius: 25px;
    padding: 4px;
    width: 40px;
  }

  .white-icon {
    background-color: #ff4101;
    border-radius: 50%;
    padding: 5px;
  }

  .address-form-group input {
    border: 1px solid #dfdede;
    height: 45px;
  }

  .aadhaar-otp-btn {
    margin-top: 20px;
  }

  .top-header-content {
    padding: 20px 0px 15px;
}

  @media (max-width: 600px) {
    .text-fatch {
      height: auto;
    }

    .radio-container {
        margin: 5px 0px;
    }

    .radio-container input {
        margin-right: 10px;
        accent-color: #FF4100;
    }

    .radio-container label {
        font-size: 12px;
        color: #000000;
        line-height: 15px;
        font-family: 'Mulish-Light';
    }
    .file > label {
        font-size: 1rem;
        font-weight: 300;
        cursor: pointer;
        background-color: #FFFFFF;
        padding: 10px 12px;
        font-size: 14px;
        line-height: 16px;
        color: #000000;
        display: block;
        font-family: "Mulish-Light";
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
        border-radius: 5px;
        width: 100%;
        height: auto;
        border: 1px dashed #707070;
    }
    .icon_img {
        background-color: #ff4100;
        border-radius: 25px;
        padding: 4px;
        width: 40px;
    }
    .aadhaar-otp-btn{
        text-align: center;
        margin-top: 30px;
    }

  }
}

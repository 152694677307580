.thanku_container {
  .thanku_page {
    width: 100%;
    min-height: 88vh;
    background-color: #ffffff;
    // position: relative;
  }

  .thanku-img {
    width: 100%;
    text-align: center;
    padding-top: 40px;
    padding-bottom: 40px;
    border-right: 2px dashed #d4d4d4;
  }

  .thanku_application_box {
    display: flex;
    align-items: end;
    justify-content: center;
  }

  .tick_icon {
    height: 20px;
    margin-right: 10px;
  }

  .thanku_text {
    font-family: "Mulish-SemiBold";
    font-size: 14px;
    margin-bottom: 7px !important;
    padding-top: 25px;
  }

  .thanku_subtext {
    font-family: "Mulish-bold";
    font-size: 14px;
  }

  .loan_account {
    display: flex;
    margin-bottom: 15px;
  }

  .loan_account .loan_text {
    font-family: "Mulish-Light";
    font-size: 14px;
  }

  .loan_account .loan_number {
    font-family: "Mulish-SemiBold";
    font-size: 14px;
    color: #ff4100;
  }

  p.loan_text {
    width: 200px;
  }

  .line_box hr {
    width: 50%;
    text-align: left;
    margin-left: 0px;
  }

  .email-text {
    font-family: "Mulish-Light";
    font-size: 15px;
    color: #575757;
    margin-top: 30px !important;
    margin-bottom: 30px !important;
    letter-spacing: 0.6px;
  }

  .Terms_btn {
    background-color: #e35626;
    color: #ffffff;
    padding: 8px 15px;
    border: none;
    border-radius: 5px;
    font-size: 15px;
    font-family: "Mulish-SemiBold";
    letter-spacing: 0.56px;
    display: flex;
  }

  .dawonload_icon {
    height: 22px;
    margin-right: 8px;
  }

  .GroChain_btn {
    border: 1px solid #e35626 !important;
    color: #e35626;
    padding: 8px 20px;
    border: none;
    border-radius: 5px;
    background-color: #ffffff;
    font-size: 14px;
    font-family: "Mulish-SemiBold";
    letter-spacing: 0.56px;
    margin-top: 35px;
  }

  img.thanku_img {
    width: 131px;
    height: auto;
  }

  .Terms_btn a:hover {
    color: #fff;
    text-decoration: none;
  }

  .Terms_btn a {
    color: #fff;
  }



  @media (max-width: 600px) {
    .thanku-img {
      border-right: 0px dashed #d4d4d4 !important;
      border-bottom: 2px dashed #d4d4d4;
    }

    .th-content {
      padding-top: 20px;
    }

    .loan_account {
      display: block;
    }

    .loan_account .loan_number {
      padding-top: 6px;
    }

    .Terms_btn {
      padding: 8px 12px;
      font-size: 12px;

    }


    .dawonload_icon {
      height: 16px;
      margin-right: 8px;
    }

    .GroChain_btn {

      font-size: 12px;

      margin-top: 22px;
    }

    .thanku_subtext {
      font-size: 13px;
    }

    img.thanku_img {
      width: 90px;
      height: auto;
    }

    .thanku_application_box {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .thanku_subtext {
      text-align: left;
    }
  }
}
.PreApprovedLoan-container {
    .PreApprovedLoan_View {
        width: 100%;
        min-height: 86vh;
        height: 86vh;
        background-color: #FFFFFF;
        position: relative;
        padding: 0 15px;
    }

    .loan_img {
        width: 410px;
        height: auto;
    }

    .top-header-content h2 {
        font-size: 24px;
        color: #000000;
        line-height: 25px;
        font-family: 'Mulish-Bold';
    }

    .Login-heading {
        text-align: center;
        margin-top: 27px;
        margin-bottom: 25px;
    }

    .Login-heading h1 {
        font-size: 20px;
        font-family: 'Mulish-SemiBold';
        line-height: 22px;
        padding-bottom: 4px;
        color: #000000;
    }

    .Login-heading h1 span {
        color: #ff4100;
    }

    .btn-view {
        border-radius: 5px;
        padding: 6px 20px;
        width: 130px;
        height: 40px;
        background-color: #E35626 !important;
        color: #FFFFFF !important;
        line-height: 18px;
        border: none;
        font-size: 14px;
        font-family: 'Mulish-SemiBold';
        letter-spacing: 0.56px;
    }

    .btn-interested {
        border-radius: 5px;
        padding: 6px 20px;
        height: 40px;
        background-color: #ffffff !important;
        color: #E35626 !important;
        line-height: 18px;
        border: none;
        font-size: 16px;
        border: 1px solid #E35626;
        font-family: "Mulish-SemiBold";
        letter-spacing: 0.56px;
        margin-left: 15px;
    }

    .btn-container {
        display: flex;
    }

    .btn-container .submit-btn {
        margin-right: 20px;
    }

    .pal-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
    }

    .pal-box h1 {
        font-size: 24px;
        font-family: 'Mulish-Bold';
        line-height: 30px;
    }

    .pal-box h2 {
        font-size: 20px;
        line-height: 30px;
        font-family: 'Mulish-SemiBold';
        padding: 27px 0px;
    }

    .span_color {
        color: #E35626;
        font-family: 'Mulish-Bold';
    }

    @media screen and (max-width: 600px) {
        .content-container{
            padding: 25px 2px;
        }
        .loan_img {
            width: 100%;
        }

        .top-header-content h2 {
            font-size: 20px;
        }

        .Login-heading h1 {
            font-size: 18px;
        }

        .Login-heading {
            margin-top: 16px;
            margin-bottom: 16px;
        }

        .btn-container .submit-btn {
            margin-right: 10px;
        }

        .pal-box h1 {
            font-size: 20px;
            line-height: 26px;
            text-align: center;
        }

        .pal-box h2 {
            font-size: 18px;
            line-height: 26px;
            padding: 24px 0px;
            text-align: center;
        }

        .btn-view {
            padding: 6px 8px;
            width: 110px;
            margin-bottom: 10px;
        }

        .btn-interested {
            padding: 6px 8px;
            margin-left: 10px;
            width:150px
        }

        .submit-btn {
            text-align: center;
        }

        .pal-box h2 {
            font-size: 16px;
        }
    }
}
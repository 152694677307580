.rejection-container {
  .eKYC_View {
    width: 100%;
    min-height: 88vh;
    background-color: #ffffff;
    position: relative;
  }

  .eKYC_body {
    padding-left: 18px;
    padding-right: 18px;
    margin-top: 22px;
  }

  .top-header {
    display: flex;
    margin-bottom: 10px;
    justify-content: center;
  }

  .top-header-icon {
    display: flex;
    margin-right: 20px;
  }

  .top-header-icon img {
    width: 40px;
    height: auto;
  }

  .top-header-content h2 {
    font-size: 20px;
    color: #000000;
    line-height: 25px;
    font-family: "Mulish-SemiBold";
  }

  .top-header-content p {
    font-size: 12px;
    color: #000000;
    line-height: 18px;
    font-family: "Mulish-Light";
  }

  .business-entity {
    margin-top: 20px;
  }

  .business-entity h3 {
    font-size: 16px;
    color: #000000;
    line-height: 18px;
    font-family: "Mulish-SemiBold";
    text-align: center;
  }

  .business-entity p {
    margin: 20px !important;
    font-size: 16px;
    color: #000000;
    line-height: 18px;
    font-family: "Mulish-SemiBold";
    text-align: center;
  }

  .btn-main-container {
    display: flex;
    justify-content: center;
  }

  .btn-container {
    padding: 8px;
  }

  .btn-view {
    border-radius: 5px;
    width: 107px;
    height: 30px;
    background-color: #e35626;
    color: #ffffff;
    line-height: 18px;
    border: none;
    font-size: 14px;
  }

  .btn-view-border {
    background-color: transparent;
    border: 1px solid #e35626;
    color: #e35626;
  }
}

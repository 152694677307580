.PanDetails_container {
  .Login_View {
    width: 100%;
    min-height: 100vh;
    // height: 86vh;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .navbar {
    background-color: #ffffff;
    padding: 12px 18px;
  }

  .ugro-logo {
    width: 70px;
    height: auto;
  }

  .businessentity-container {
    display: flex;
    // justify-content: center;
    align-items: center;
    margin-top: 8px;
  }

  .businessentity-img {
    width: 38px;
    height: auto;
    margin-bottom: 4px;
    margin-right: 10px;
  }

  .Login-heading {
    text-align: center;
    margin-bottom: 20px;
  }

  .Login-heading h1 {
    font-size: 22px;
    font-family: "Mulish-ExtraBold";
    line-height: 28px;
    padding-bottom: 4px;
    color: #000000;
    letter-spacing: 0.56px;
    text-align: left;
  }

  .Login-heading h1 span {
    color: #ff4100;
  }

  .Login-heading p {
    font-size: 15px;
    font-family: "Mulish-Regular";
    line-height: 18px;
    padding-bottom: 4px;
  }

  .Login-form-submit {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 8px;
  }

  .checkbox-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 5px;
  }

  .checkbox-container input {
    accent-color: #ff4100;
    margin-top: 2px;
    width: 15px;
    height: 15px;
    cursor: pointer;
  }

  .checkbox-container label {
    font-size: 14px;
    line-height: 18px;
    margin-left: 10px;
    cursor: pointer;
    color: #707070;
  }

  .submit-btn {
    margin: 20px 0px 5px;
    text-align: center;
  }

  .btn-view {
    border-radius: 5px;
    width: 150px;
    height: 45px;
    background-color: #e35626 !important;
    color: #ffffff !important;
    line-height: 18px;
    border: none;
    font-size: 16px;
    font-family: "Mulish-SemiBold";
  }

  .btn-view-disabled {
    background-color: #c7c7c7;
    color: #707070;
  }

  .business-entity {
    margin: 10px 0px;
  }

  .business-entity h3 {
    font-size: 16px;
    color: #000000;
    line-height: 18px;
    font-family: "Mulish-SemiBold";
    padding-bottom: 8px;
  }

  .radio-container {
    margin: 5px 0px;
    position: relative;
  }

  .radio-container input {
    // margin-right: 10px;
    accent-color: #ff4100;
    position: absolute;
    top: 0px;
    left: 0px;
  }

  .radio-container input:checked ~ label {
    color: #ff4100;
  }

  .radio-container label {
    font-size: 12px;
    color: #9b9b9b;
    line-height: 15px;
    font-family: "Mulish-Regular";
    margin-left: 22px;
  }

  .form-input {
    padding-bottom: 15px;
    width: 100%;
  }

  .form-input label {
    font-size: 16px;
    color: #000000;
    line-height: 28px;
    font-family: "Mulish-SemiBold";
  }

  .form-input select {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #dfdede;
    background-color: #ffffff;
    // padding: 10px 12px;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    display: block;
    font-family: "Mulish-Light";
    height: 45px;
  }

  .form-input select:focus {
    outline: none !important;
    box-shadow: none;
  }

  .form-input select:focus-visible {
    outline: none;
  }

  input[type="date"]::-webkit-calendar-picker-indicator {
    color: rgba(0, 0, 0, 0);
    opacity: 1;
    display: block;
    background: none no-repeat !important;
    width: 20px;
    height: 20px;
    border-width: thin;
  }

  .date-image-overlap {
    background-color: #ffffff;
    position: absolute;
    width: 12px;
    height: 98%;
    right: 2px;
    top: 2px;
    z-index: 99999;
  }

  input[type="date"] {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #dfdede;
    background-color: transparent;
    padding: 10px 12px;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    display: block;
    font-family: "Mulish-Light";
    z-index: 9999;
    position: absolute;
  }

  .date-container {
    position: relative;
    height: 40px;
  }

  .date-img {
    background-color: #fff;
    height: 26px;
    padding: 4px;
    position: absolute;
    right: 0px;
    top: 8px;
    width: 24px;
    margin-right: 12px;
  }

  .ugroLink {
    text-decoration: none;
  }

  .helper_text {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 8px;
  }

  .helper_text p {
    font-size: 10px;
    color: #9b9b9b;
    line-height: 16px;
    font-family: "Mulish-Regular";
  }

  .helper-img {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }

  .login-box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-image: linear-gradient(rgb(255 215 215 / 40%), white);
  }

  .login-form {
    box-shadow: 0px 0px 8px 2px #cbcbcb;
    background-color: #fff;
    border-radius: 10px;
    padding: 20px 30px;
    margin: 0px 15px;
  }

  .login-heading {
    font-size: 20px;
    font-family: "Mulish-SemiBold";
    line-height: 22px;
    padding-bottom: 4px;
    color: #000000;
  }

  .ugro-logo-img {
    margin: 30px 0;
  }

  .ugro-logo-img {
    margin: 30px 0;
    height: 40px;
    margin-top: 0;
  }
  .input-form {
    margin-top: 20px;
    width: 100%;
  }

  @media (max-width: 600px) {
    .businessentity-img {
      width: 100px;
    }

    .businessentity-container {
      display: flex;
      align-items: flex-start;
      margin-top: 8px;
    }

    .businessentity-img {
        width: 15px;
        height: auto;
        margin-bottom: 4px;
        margin-right: 10px;
    }

    .Login-heading {
        text-align: center;
        margin-top: 22px;
        margin-bottom: 22px;
    }

    .Login-heading h1 {
        font-size: 20px;
        font-family: 'Mulish-SemiBold';
        line-height: 22px;
        padding-bottom: 4px;
        color: #000000;
    }

    .Login-heading h1 span {
        color: #ff4100;
    }

    .Login-heading p {
        font-size: 12px;
        font-family: 'Mulish-Regular';
        line-height: 18px;
        padding-bottom: 4px;
    }

    .Login-form-submit {
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 8px;
    }

    .checkbox-container {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 5px;
    }

    .checkbox-container input {
        accent-color: #FF4100;
        margin-top: 2px;
        width: 15px;
        height: 15px;
        cursor: pointer;
    }

    .checkbox-container label {
        font-size: 14px;
        line-height: 18px;
        margin-left: 10px;
        cursor: pointer;
        color: #707070;
    }

    .submit-btn {
        margin: 20px 0px;
        text-align: center;
    }

    .btn-view {
        border-radius: 5px;
        width: 107px;
        height: 30px;
        background-color: #E35626 !important;
        color: #FFFFFF !important;
        line-height: 18px;
        border: none;
        font-size: 14px;
    }

    .btn-view-disabled {
        background-color: #C7C7C7;
        color: #707070;
    }


    .top-header {
        display: flex;
        margin-bottom: 10px;
    }

    .top-header-icon {
        margin-right: 20px;
    }

    .top-header-icon img {
        width: 40px;
        height: auto;
    }

    .top-header-content h2 {
        font-size: 20px;
        color: #000000;
        line-height: 25px;
        font-family: 'Mulish-SemiBold';
    }

    // .top-header-content p {
    //     font-size: 12px;
    //     color: #000000;
    //     line-height: 18px;
    //     font-family: 'Mulish-Light';
    // }


    .business-entity {
        margin: 10px 0px;
    }

    .business-entity h3 {
        font-size: 14px;
        color: #000000;
        line-height: 18px;
        font-family: 'Mulish-SemiBold';
        padding-bottom: 8px;
    }

    .radio-container {
        margin: 5px 0px;
        position: relative;
    }

    .radio-container input {
        // margin-right: 10px;
        accent-color: #FF4100;
        position: absolute;
        top: 0px;
        left: 0px;
    }

    .radio-container input:checked~label {
        color: #FF4100;
    }

    .radio-container label {
        font-size: 12px;
        color: #9B9B9B;
        line-height: 15px;
        font-family: 'Mulish-Regular';
        margin-left: 22px;
    }

    .form-input {
        padding-bottom: 15px;
        width: 100%;
    }

    .form-input label {
        font-size: 14px;
        color: #000000;
        line-height: 28px;
        font-family: 'Mulish-Regular';
    }

    .form-input select {
        width: 100%;
        border-radius: 5px;
        border: 1px solid #dfdede;
        background-color: #FFFFFF;
        // padding: 10px 12px;
        font-size: 14px;
        line-height: 16px;
        color: #000000;
        display: block;
        font-family: 'Mulish-Light';
    }

    .form-input select:focus {
        outline: none !important;
        box-shadow: none;
    }

    .form-input select:focus-visible {
        outline: none;
    }

    input[type="date"]::-webkit-calendar-picker-indicator {
        color: rgba(0, 0, 0, 0);
        opacity: 1;
        display: block;
        background: none no-repeat !important;
        width: 20px;
        height: 20px;
        border-width: thin;
    }

    .date-image-overlap {
        background-color: #FFFFFF;
        position: absolute;
        width: 12px;
        height: 98%;
        right: 2px;
        top: 2px;
        z-index: 99999;
    }

    input[type="date"] {
        width: 100%;
        border-radius: 5px;
        border: 1px solid #dfdede;
        background-color: transparent;
        padding: 10px 12px;
        font-size: 14px;
        line-height: 16px;
        color: #000000;
        display: block;
        font-family: 'Mulish-Light';
        z-index: 9999;
        position: absolute;
    }

    .date-container {
        position: relative;
        height: 40px;
    }

    .date-img {
        background-color: #fff;
        height: 26px;
        padding: 4px;
        position: absolute;
        right: 0px;
        top: 8px;
        width: 24px;
        margin-right: 20px;

    }

    .ugroLink {
        text-decoration: none;
    }

    .helper_text {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-bottom: 8px;

    }

    .helper_text p {
        font-size: 10px;
        color: #9B9B9B;
        line-height: 16px;
        font-family: 'Mulish-Regular';
    }

    .helper-img {
        width: 16px;
        height: 16px;
        margin-right: 4px;
    }

    .login-box {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        background-image: linear-gradient( rgb(255 215 215 / 40%), white);
    }

    .login-form {
        box-shadow: 0px 0px 8px 2px #cbcbcb;
        background-color: #fff;
        border-radius: 10px;
        padding: 20px 30px;
        margin: 0px 15px;
    }

    .login-heading {
        font-size: 20px;
        font-family: 'Mulish-SemiBold';
        line-height: 22px;
        padding-bottom: 4px;
        color: #000000;
    }

    .ugro-logo-img {
        margin: 30px 0;
    }

    .ugro-logo-img {
        margin: 30px 0;
        height: 40px;
        margin-top: 0;
    }
    .input-form{
        margin-top: 20px;
        width: 100%;
    }
  }
}

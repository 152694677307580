.SanctionLetterESign-container{
    .SanctionLetterESign_View{
        width: 100%;
        min-height: 88vh;
        background-color: #FFFFFF;
        position: relative;
    }
    .SanctionLetterESign_body{
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 26px;
        padding-right: 26px;
        flex-direction: column;
        padding-top: 12px;
    }
    .contant-view{
        padding-left: 11px;
        padding-right: 11px;
    }
    .heading_view{
        font-size: 16px;
        font-family: 'Mulish-ExtraBold';
        line-height: 20px;
        padding-bottom: 20px;
        color: #000000;
        text-align: center;
    }
    .content_style{
        font-size: 14px;
        font-family: 'Mulish-Regular';
        line-height: 18px;
        color: #000000;
        text-align: justify;
    }
    .signing_at{
        font-size: 12px;
        font-family: 'Mulish-Regular';
        line-height: 18px;
        color: #575757;
        padding-top: 6px;
    }
    .btn-main-container{
        display: flex;
        justify-content: center;
        margin-top: 18px;
        margin-bottom: 18px;
    }

    .btn-view {
        border-radius: 5px;
        height: 30px;
        background-color: #E35626;
        color: #FFFFFF;
        line-height: 18px;
        border: none;
        font-size: 14px;
        padding: 0px 18px;
    }
}
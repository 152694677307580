.gst-container {
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  .gst_View {
    width: 100%;
    min-height: 88vh;
    background-color: #ffffff;
    position: relative;
  }

  .gst_body {
    padding-left: 18px;
    padding-right: 18px;
    margin-top: 22px;
  }

  .top-header {
    display: flex;
    margin-bottom: 10px;
  }

  .top-header-icon {
    margin-right: 20px;
  }

  .top-header-icon img {
    width: 40px;
    height: auto;
  }

  .top-header-content h2 {
    font-size: 20px;
    color: #000000;
    line-height: 25px;
    font-family: "Mulish-SemiBold";
  }

  .top-header-content p {
    font-size: 12px;
    color: #000000;
    line-height: 18px;
    font-family: "Mulish-Light";
  }

  .form-input label {
    font-size: 16px;
    color: #000000;
    line-height: 28px;
    font-family: "Mulish-SemiBold";
  }

  .text-fatch {
    font-size: 14px;
    width: 100%;
    border-radius: 5px;
    border: none;
    border: 1px solid #dedede;
    padding: 8px 10px;
    font-family: "Mulish-Light";
    background-color: #f4f4f4;
  }

  .icon_img {
    background-color: #ff4100;
    border-radius: 25px;
    padding: 4px;
    width: 40px;
  }

  .aadhaar-otp-btn {
    text-align: center;
    margin-top: 30px;
  }
  .address-form-group input {
    background-color: #f4f4f4;
    height: 45px;
  }
  .address-form-group select {
    background-color: #f4f4f4;
    height: 45px;
  }
}

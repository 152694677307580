.eKYC-container {
  .eKYC_View {
    width: 100%;
    min-height: 88vh;
    background-color: #ffffff;
    position: relative;
  }

  .eKYC_body {
    padding-left: 18px;
    padding-right: 18px;
    margin-top: 22px;
  }

  .top-header {
    display: flex;
    margin-bottom: 10px;
  }

  .top-header-icon {
    margin-right: 20px;
  }

  .top-header-icon img {
    width: 40px;
    height: auto;
  }

  .top-header-content h2 {
    font-size: 20px;
    color: #000000;
    line-height: 25px;
    font-family: "Mulish-SemiBold";
  }

  .form-input label {
    font-size: 16px;
    color: #000000;
    line-height: 28px;
    font-family: "Mulish-SemiBold";
  }

  .form-input select:focus {
    outline: none !important;
    box-shadow: none;
  }

  .form-input select:focus-visible {
    outline: none;
  }

  .file>input[type="file"] {
    display: none;
  }

  .file>label {
    font-size: 1rem;
    font-weight: 300;
    cursor: pointer;
    width: 100%;
    border-radius: 5px;
    border: 1px dashed #707070;
    background-color: #ffffff;
    padding: 10px 12px;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    display: block;
    font-family: "Mulish-Light" !important;
    height: 45px !important;
    display: flex !important;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0;
  }

  .Clip-img {
    width: 18px;
    height: auto;
    transform: rotatey(180deg);
  }

  .file label span {
    float: right;
  }

  hr.hr-line {
    border: 1px solid #dadada;
    width: 16%;
    margin: unset;
    margin-top: 10px;
  }

  .address-form-group {
    margin-top: 15px;
  }

  .address-form-group label {
    font-size: 16px;
    font-family: "Mulish-SemiBold";
  }

  .address-form-group input {
    font-size: 14px;
    width: 100%;
    border-radius: 5px;
    border: none;
    border: 1px solid #dfdede;
    padding: 4px 10px;
    font-family: "Mulish-Light";
    height: 45px;
  }

  :focus-visible {
    outline: -webkit-focus-ring-color auto 0px;
  }

  .aadhaar-otp-btn {
    text-align: center;
    margin: 25px 0 0px;
  }

  .resent-otp-text {
    color: #ea652b;
    font-size: 16px;
    font-family: "Mulish-SemiBold";
    margin-bottom: 10px !important;
    cursor: pointer;
  }

  .icon_img {
    background-color: #ff4100;
    border-radius: 25px;
    padding: 4px;
    width: 40px;
  }

  .timer_view {
    font-size: 14px;
    padding-top: 7px;
    font-family: 'Mulish-SemiBold';
    color: #FF4100;
  }

  .top-header-content {
    padding: 20px 0px 5px;
  }

  .confirm_submit {
    font-size: 14px;
    padding-top: 10px;

  }

  .otp-field {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #F4F4F4;
    background-color: #FFFFFF;
    padding: 10px 12px;
    font-size: 14px;
    line-height: 18px;
    color: #707070;
    display: block;
  }

  .otp-field:focus-visible {
    outline: none;
  }

  .ekyc-otp .input-box {
    width: 45px;
    border-radius: 5px;
    border: 1px solid #cdcdcd;
    background-color: #FFFFFF;
    height: 45px;
    padding: 2px;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #000000;
    font-family: "Mulish-Light";
    margin: 0px 6px;
  }

  .ekyc-otp .input-box:focus {
    outline: none;
  }

  .word-break {
    word-break: break-all !important;
  }

  @media (max-width: 600px) {
    hr.hr-line {
      width: 35%;
    }

    .ekyc-otp .input-box {
      width: 35px;
      height: 35px;
    }

    .word-break {
      width: 100%;
    }
  }

}
.camera-container {
  .camera-body {
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 8px;
    padding: 24px;
    text-align: center;
  }
  .btn-view {
    border-radius: 5px;
    height: 40px;
    background-color: #e35626;
    color: #ffffff;
    line-height: 18px;
    border: none;
    font-size: 14px;
    padding: 0px 20px;
    margin-top: 20px;
  }
  .modal-close-icon {
    position: absolute;
    right: 0px;
    top: 0px;
    background-color: #fff;
    border-radius: 100px;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  img.cancle {
    width: 16px;
    height: auto;
  }
}

.UploadSelfie-container {
  .UploadSelfie_View {
    width: 100%;
    min-height: 88vh;
    background-color: #ffffff;
    position: relative;
  }

  .UploadSelfie_body {
    padding-left: 18px;
    padding-right: 18px;
    margin-top: 22px;
  }

  .top-header {
    margin-bottom: 10px;
  }

  .top-header-icon {
    margin-right: 20px;
  }

  .top-header-icon img {
    width: 40px;
    height: auto;
  }

  .top-header-content h2 {
    font-size: 20px;
    color: #000000;
    line-height: 25px;
    font-family: "Mulish-SemiBold";
  }

  .business-entity h3 {
    font-size: 16px;
    color: #000000;
    line-height: 18px;
    font-family: "Mulish-SemiBold";
    text-align: center;
  }

  .btn-main-container {
    display: flex;
    justify-content: center;
  }

  .btn-view {
    border-radius: 5px;
    height: 40px;
    background-color: #e35626;
    color: #ffffff;
    line-height: 18px;
    border: none;
    font-size: 14px;
    padding: 0px 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .btn-view-border {
    background-color: transparent;
    border: 1px solid #e35626;
    color: #e35626;
  }

  .UploadSelfie-view {
    text-align: center;
    margin-bottom: 30px;
  }

  .UploadSelfie-img {
    width: 110px;
    height: auto;
    border-radius: 100px;
  }

  .camera-img {
    width: 24px;
    height: auto;
    margin-right: 8px;
  }

  .top-header-content {
    display: flex;
    align-items: center;
    padding: 20px 0px 0px;
  }

  .upload_Lable {
    width: 250px !important;
    
  }

  .btn-view {
    justify-content: center;
  }

  @media (max-width: 600px) {
    .icon-heading {
      align-items: center;
    }

    .UploadSelfie-view {
      margin-bottom: 30px;
      margin-top: 10px;
    }

    .mobile-none {
      display: none;
    }

    .upload_Lable {
      width: 80% !important;
    }

    .btn-view {
      justify-content: center;
      padding: 0px 10px;
    }

    .submit_btn{
      width:140px
    }
  }
}
.Congratulations-container {
  .Congratulations_View {
    width: 100%;
    min-height: 88vh;
    background-color: #ffffff;
    position: relative;
  }

  .Congratulations_body {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
    flex-direction: column;
  }

  .Productquality-img-view {
    margin: 10px 0px 10px;
    text-align: right;
  }

  .Productquality-img {
    width: 360px;
    height: auto;
  }

  .contant-view h3 {
    font-size: 26px;
    font-family: "Mulish-SemiBold";
    line-height: 33px;
    padding-bottom: 10px;
    color: #e35626;
  }
  .contant-view p {
    font-size: 18px;
    font-family: "Mulish-Regular";
    line-height: 30px;
    padding-bottom: 30px;
    color: #000000;
    // padding: 0px 40px;
  }
  .info_container {
    margin-bottom: 30px;
    background-color: #fff8f5;
    padding: 18px 30px;
    width: 100%;
    text-align: center;
  }
  .info-img {
    width: 44px;
    height: auto;
  }
  .info_container h4 {
    font-size: 16px;
    font-family: "Mulish-SemiBold";
    line-height: 20px;
    color: #ff4100;
    padding-top: 5px;
    padding-bottom: 2px;
  }
  .info_container p {
    font-size: 14px;
    font-family: "Mulish-Regular";
    line-height: 18px;
    color: #000000;
  }
  .btn-main-container {
    display: flex;
    justify-content: center;
  }

  .btn-view {
    border-radius: 5px;
    width: 150px;
    height: 40px;
    background-color: #e35626;
    color: #ffffff;
    line-height: 18px;
    border: none;
    font-size: 14px;
  }

  .counter-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    margin-right: 12%;
  }

  .contant-view {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 15%;
  }

  .Productquality-continer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 10%;
    height: 100%;
  }

  p.counter-text {
    padding: 0px !important;
  }

  @media screen and (max-width: 600px) {
    .Productquality-img {
      width: 251px;
    }
    .contant-view {
      text-align: center;
    }

    .counter-box {
      margin-right: 0%;
    }

    .contant-view p {
      font-size: 14px;
      line-height: 26px;
    }

    .info-img {
      width: 36px;
    }

    .info_container h4 {
      font-size: 14px;
    }

    .contant-view h3 {
      font-size: 20px;
    }

    .info_container {
      padding: 18px 15px;
    }

    .Productquality-continer {
      margin-right: 0%;
      justify-content:center;
    }

    .contant-view {
      margin-right: 0%;
      align-items: center;
    }
  }
}

.udyamregistration-container {
  .eKYC_View {
    width: 100%;
    min-height: 88vh;
    background-color: #ffffff;
    position: relative;
  }

  .eKYC_body {
    padding-left: 18px;
    padding-right: 18px;
    margin-top: 22px;
  }

  .top-header {
    margin-bottom: 10px;
  }

  .top-header-icon {
    margin-right: 20px;
  }

  .top-header-icon img {
    width: 40px;
    height: auto;
  }

  .top-header-content h2 {
    font-size: 20px;
    color: #000000;
    line-height: 25px;
    font-family: "Mulish-SemiBold";
  }

  .business-entity {
    margin-top: 20px;
  }

  .business-entity h3 {
    font-size: 16px;
    color: #000000;
    line-height: 18px;
    font-family: "Mulish-SemiBold";
    text-align: center;
  }

  .btn-main-container {
    display: flex;
    justify-content: start;
  }

  .btn-container {
    // padding: 8px;
  }

  .btn-view {
    border-radius: 5px;
    width: 150px;
    height: 40px;
    background-color: #e35626 !important;
    color: #ffffff !important;
    line-height: 18px;
    border: none;
    font-size: 16px;
  }

  .btn-view-border {
    background-color: transparent;
    border: 1px solid #e35626;
    color: #e35626;
  }

  .form-body {
    margin-top: 30px;
  }

  .udaym-number-apply {
    padding: 0px 0px 10px;
  }

  .udaym-number-apply p {
    font-size: 14px;
    color: #000000;
    line-height: 18px;
    font-family: "Mulish-Regular";
    text-align: left;
  }

  .udaym-number-apply p a {
    color: #ff4100;
  }

  .udaym-number-apply p a:hover {
    text-decoration: none;
    color: #ff4100;
  }

  .bank-mandate-input {
    position: relative;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #DFDEDE;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    height: 45px;
  }

  .form-label {
    padding: 10px 8px;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    display: block;
    font-family: "Mulish-Light";
  }

  .bank-mandate-img {
    position: absolute;
    left: 18px;
    top: 8px;
  }

  .Udyam-input {
    width: 16px;
    height: 20px;
    border: none;
    border-bottom: 1px solid #000000;
    border-radius: 0px;
    padding: 0px;
    text-align: center;
    margin-right: 6px;
  }

  .form-group {
    margin: 0px;
  }

  .form-input input {
    width: 30px;
    border-radius: 0px;
    border-bottom: 1px solid grey !important;
    background-color: #ffffff;
    padding: 4px 2px;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    display: block;
    font-family: "Mulish-Light";
    border: none;
    margin: 2px;
    height: 22px;
  }

  .form-input input:focus {
    box-shadow: none;
  }

  .form-label-hyphen {
    color: grey;
    padding: 2px 6px;
    font-size: 16px;
  }

  .custominput-container .mobileNumber-input {
    width: 100% !important;
    border-radius: 5px !important;
    border: 1px solid #DFDEDE !important;
    background-color: #ffffff;
    padding: 10px 12px !important;
    font-size: 14px;
    line-height: 16px !important;
    color: #000000;
    display: block;
    font-family: "Mulish-Light";
    height: 45px;
  }

  .input_container {
    margin-top: 26px;
  }

  .cursor {
    cursor: pointer;
  }

  .radio-container {
    margin: 5px 0px;
    display: flex;
    align-items: center;
  }

  .radio-container input {
    margin-right: 10px;
    accent-color: #ff4100;
    margin-top: 0;
  }

  .radio-container label {
    font-size: 12px;
    color: #000000;
    line-height: 15px;
    font-family: "Mulish-Light";
    margin-bottom: 0;
  }

  .udhyam-text-box {
    display: flex;
    align-items: self-start;
    margin-top: 15px;
  }

  // .udhyam-text-box {
  //   font-family: "Mulish-bold";
  //   font-size: 14px;
  // }
  .udhyam-text-box p {
    letter-spacing: 0px;
    font-family: "Mulish-Bold";
    color: #000000;
    font-size: 16px;
    text-align: left;
    padding-top: 5px;
  }

  .nofound-img {
    height: 30px;
    margin-right: 10px;
    margin-top: 3px;
  }

  @media screen and (max-width: 600px) {
    .content-container {
      margin: 0px 12px;
      margin-top: -42px;
    }

    .input_container {
      margin-top: 0px;
    }

    .form-label {
      padding: 10px 6px;
      font-size: 12px;
    }

    .form-input input {
      width: 13px;
      padding: 4px 0px;
      font-size: 12px;
    }

    .content-container {
      padding: 25px 0px;

    }
    .udaym-number-apply p {
      text-align:center;
    }

    .btn-main-container {
      justify-content:center;
    }

    
  .btn-container {
    padding: 8px;
  }
  }
}
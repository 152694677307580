.CibilFetchReject-container {
    .CibilFetchReject_View {
        width: 100%;
        min-height: 86vh;
        height: 86vh;
        background-color: #FFFFFF;
        position: relative;
    }

   
    .CibilFetchReject_body {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 80%;
        padding-left: 18px;
        padding-right: 18px;
        flex-direction: column;
    }

    .reject-img{
        width: 150px;
        height: auto;
        margin-bottom: 5px;
    }

    .CibilFetchReject_body h1 {
        font-size: 14px;
        font-family: 'Mulish-SemiBold';
        line-height: 18px;
        padding-bottom: 10px;
        color: #000000;
    }

    .CibilFetchReject_body p {
        font-size: 14px;
        font-family: 'Mulish-Light';
        line-height: 18px;
        color: #000000;
        text-align: center;
    }

    .close-icon-top{
        position: absolute;
        top: 8px;
        right: 12px;
    }

    .crossimg-img{
        width: 24px;
        height: auto;
        cursor: pointer;
    }

    .close-icon {
        display: block;
        width: 100%;
        text-align: right;
    }

    h1.cfr-heading {
        font-size: 20px;
        font-family: 'Mulish-SemiBold';
        padding-bottom: 10px;
    }
    p.cfr-text {
        font-size: 16px;
        font-family: 'Mulish-Regular';
    }
    .re_initiate_btn{
        background-color: #e35626!important;
        border: none;
        border-radius: 5px;
        color: #fff!important;
        font-size: 16px;
        height: 40px;
        line-height: 18px;
        width: 150px;
    }
    @media (max-width: 600px) {
        .re_initiate_btn {
            width: 190px;
        }
    }
}
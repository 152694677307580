.NotAOfferModal-container {
    .modal-content {
        background-color: #FFFFFF !important;
        margin: auto;
        padding: 10px;
    }

    .content-view {
        text-align: center;
        padding: 25px 0px;

    }

    .content-view p {
        color: #000;
        font-size: 18px;
        line-height: 28px;
        font-family: 'Mulish-SemiBold';
    }

    .ok-btn {
        border-radius: 5px;
        width: 130px;
        height: 40px;
        background-color: #E35626 !important;
        color: #FFFFFF !important;
        line-height: 18px;
        border: none;
        font-size: 16px;
        margin-top: 25px;
    }
}
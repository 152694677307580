.panupload-container {
  .Login_View {
    width: 100%;
    min-height: 100vh;
    background-color: #ffffff;
  }

  .body-container {
    padding: 15px 0px;
  }

  .top-header {
    margin-bottom: 10px;
  }

  .top-header-icon {
    margin-right: 20px;
  }

  .top-header-icon img {
    width: 40px;
    height: auto;
  }

  .top-header-content h2 {
    font-size: 20px;
    color: #000000;
    line-height: 25px;
    font-family: "Mulish-SemiBold";
  }

  .panUpload-heading {
    font-size: 14px;
    font-family: "Mulish-bold";
    color: rgb(0, 0, 0);
    padding: 15px 0;
    letter-spacing: 0.56px;
    text-align: center;
  }

  .upload-btn {
    border-radius: 5px;
    width: 150px;
    height: 40px;
    background-color: #e35626;
    color: #ffffff;
    line-height: 18px;
    border: none;
    font-size: 14px;
    font-family: "Mulish-Semibold";
  }

  .form-input select:focus {
    outline: none !important;
    box-shadow: none;
  }

  .form-input select:focus-visible {
    outline: none;
  }

  .file>input[type="file"] {
    display: none;
  }

  .file1>input[type="file"] {
    display: none;
  }

  .pan-card>label {
    font-size: 1rem;
    font-weight: 300;
    cursor: pointer;
    background-color: #ffffff;
    padding: 10px 12px;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    display: block;
    font-family: "Mulish-Light";
    box-shadow: 0px 0px 7px #00000029;
    border-radius: 5px;
    width: 150px !important;
    height: 130px !important;
    margin: auto;
    margin-bottom: 30px;
    border: none !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .pan-card1>label {
    font-size: 1rem;
    font-weight: 300;
    cursor: pointer;
    background-color: #ffffff;
    padding: 10px 12px;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    display: block;
    font-family: "Mulish-Light";
    color: #ff4100;
    border: none !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .pan_upload_box {
    box-shadow: 0px 3px 6px #00000029;
    background-color: white;
    margin: 40px 20px;
    padding: 20px;
    text-align: center;
    border-radius: 10px;
  }

  .file--upload label h3 {
    font-family: "Mulish-bold";
    font-size: 15px;
  }

  .file--upload label p {
    background-color: #cbcbcb;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    padding-top: 1px;
    font-size: 19px;
    font-family: "Mulish-Regular";
    color: white;
    margin-top: 7px !important;
    box-shadow: 0px 3px 6px #00000029;
    text-align: center;
  }

  .card-img {
    width: 246px;
    height: 131px;
    border-radius: 12px;
  }

  .verified_icon {
    width: 25px;
    height: 20px;
    margin-right: 7px;
  }

  .verified_icon_container {
    display: flex;
    padding: 21px 0px;
    align-items: center;
  }

  .notverified_icon_container {
    display: flex;
    padding: 21px 0px;
  }

  .notverified_icon_container p {
    font-size: 14px;
    font-family: "Mulish-SemiBold";
  }

  .verified_icon_container p {
    font-size: 14px;
    font-family: "Mulish-SemiBold";
  }

  .submit_container {
    text-align: center;
    margin-top: 20px;
  }

  .submit_container p {
    font-family: "Mulish-SemiBold";
    color: #ff4100;
    font-size: 14px;
    margin-top: 18px !important;
    cursor: pointer;
    display: block;
  }

  .panselected_file_name {
    font-size: 12px;
    font-family: "Mulish-SemiBold";
    padding-bottom: 10px;
  }

  .card-icon-img {
    margin-top: 9px;
    width: 90px;
    height: 50px;
    border-radius: 12px;
  }

  .pan-upload-box {
    text-align: center;
  }

  @media(max-width:600px) {

    .file--upload label h3 {
      width: 100%;
    }
  }

}
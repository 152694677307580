.panding_thanku_container {
  .panding_thanku_page {
    width: 100%;
    min-height: 88vh;
    background-color: #ffffff;
    // position: relative;
  }

  .thanku-img {
    width: 100%;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 15px;
  }

  .thanku_application_box {
    text-align: center;
    margin-bottom: 30px;
  }

  .tick_icon {
    height: 20px;
    margin-right: 10px;
  }

  .thanku_text {
    font-family: "Mulish-SemiBold";
    font-size: 14px;
    margin-bottom: 7px !important;
  }

  .thanku_subtext {
    font-family: "Mulish-bold";
    font-size: 14px;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .panding_thanku_body ul {
    padding-left: 27px;
    margin-bottom: 30px;
  }

  .panding_thanku_body ul li {
    font-family: "Mulish-Light";
    font-size: 14px;
    margin-bottom: 12px !important;
  }

  .panding_thanku_body ul li::marker {
    font-family: "Mulish-bold";
    font-size: 26px;
  }

  .email-text {
    font-family: "Mulish-Light";
    font-size: 15px;
    color: #575757;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .Terms_btn_container {
    width: 100%;
  }

  .Terms_btn {
    background-color: #e35626;
    color: #ffffff;
    padding: 8px 20px;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    font-family: "Mulish-SemiBold";
    letter-spacing: 0.56px;
    margin-top: 20px;
  }

  .GroChain_btn {
    border: 1px solid #e35626 !important;
    color: #e35626;
    padding: 8px 20px;
    border: none;
    border-radius: 5px;
    background-color: #ffffff;
    font-size: 14px;
    font-family: "Mulish-SemiBold";
    letter-spacing: 0.56px;
    margin-top: 25px;
    margin-bottom: 20px;
  }

  .dawonload_icon {
    height: 15px;
    margin-right: 8px;
  }

  .pt-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding-left: 25px;
  }

  .pt-left ul {
    margin: 0px;
    padding: 0px;
  }

  ::marker {
    font-size: 20px;
  }

  .pt-left ul li {
    font-size: 14px;
    font-family: "Mulish-SemiBold";
    line-height: 28px;
  }

  hr.pt-devider {
    width: 130px;
    text-align: left;
    margin-left: 0px;
    margin-top: 1px;
    border-top: 2px solid #cccccc;
    border-radius: 10px;
    margin-bottom: 0px;
  }

  .d-border {
    border-right: 2px dotted #0000003d;
  }

  .pt-icon-list {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .Terms_btn a:hover {
    color: #fff;
    text-decoration: none;
  }

  .Terms_btn a {
    color: #fff;
  }

  @media(max-width:600px) {
    .d-border {
      border-right: 0px dotted #0000003d !important;
      border-bottom: 2px dotted #0000003d;
    }

    .thanku_application_box {
      text-align: center;
      margin-bottom: 10px;
    }

    .pt-left {
      padding-top: 15px;
    }

    .thanku_subtext {
      text-align: left;
    }
  }
}
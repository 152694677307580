.AccessModal-container {
    .modal-content {
        background-color: #FFFFFF !important;
        margin: auto;
        padding: 10px;
    }

    .content_acces-view {
        text-align: center;
        padding: 25px 0px;

        padding-bottom: 10px !important;
        position: relative;
    }

    .sub-heading {
        color: #000;
        font-size: 18px;
        line-height: 28px;
        font-family: 'Mulish-SemiBold';
    }

    .ok-button {
        border-radius: 5px;
        width: 130px;
        height: 40px;
        background-color: #E35626 !important;
        color: #FFFFFF !important;
        line-height: 18px;
        border: none;
        font-size: 16px;
        margin-top: 25px;
    }

    .cross_icon_img {
        text-align: right;
        margin-bottom: 15px;
        position: absolute;
        right: -18px;
        top: -18px;
    }

    .modal-body {
        padding-right: 0px;
        padding-top: 0px;
        padding-left: 0px;
    }

    .modal-content {
        border-radius: 8px;
        border-top: 3px solid #E35626 !important;
        width: 85% !important;
    }

    .cross-img {
        width: 22px;
        height: auto;
        cursor: pointer;
        background-color: white;
        border-radius: 20px;
    }
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  background-color: #ffffff;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.main-container {
  width: 100%;
  margin: auto;
  background-color: #ffffff;
  height: 100vh;
  overflow: auto;
}

.content-container {
  box-shadow: 0px 0px 10px #0000003d;
  border-radius: 10px;
  margin: 0px 35px;
  padding: 28px;
  margin-top: -42px;
  background-color: #ffffff;
  margin-bottom: 30px;
}

.modal-backdrop {
  width: 100% !important;
  height: 100% !important;
}

hr.heading-divider {
  width: 70px;
  text-align: left;
  margin: 0px;
  margin-left: 0px;
  margin-top: 1px;
  color: red;
  border-top: 3.5px solid #e35626;
  border-radius: 10px;

}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("./assets/images/down-arrow.png");
  background-repeat: no-repeat;
  background-position-x: 96%;
  background-position-y: 15px;
  background-size: 15px;
  border: 1px solid #dfdfdf;
  border-radius: 2px;
  margin-right: 2rem;
  padding: 1rem;
  padding-right: 2rem;
}

.icon-heading {
  display: flex;
  align-items: center;
  margin-top: 8px;
}

img.header-icon {
  width: 38px;
  height: auto;
  margin-bottom: 4px;
  margin-right: 10px;
}

.icon-heading p {
  font-size: 15px;
  font-family: "Mulish-Regular";
  line-height: 18px;
  padding-bottom: 4px;
}

.top-header h1 {
  font-size: 22px;
  font-family: "Mulish-ExtraBold";
  line-height: 28px;
  padding-bottom: 4px;
  color: #000000;
  letter-spacing: 0.56px;
  text-align: left;
}

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
  width: 18px;
  height: 18px;
}

.radio-container {
  display: flex;
  align-items: center;
}

.rlable {
  margin-bottom: 0px;
  line-height: 24px !important;
}

.form-input select {
  height: 45px !important;
  border: 1px solid #dfdede !important;
}

.btn-view {
  font-size: 16px !important;
  font-family: "Mulish-SemiBold" !important;
}

.top-header-content {
  display: flex;
  align-items: center;
  padding: 20px 0px 30px;
}

img.header-img {
  width: 38px;
  height: auto;
}

.top-header-title h2 {
  font-size: 22px;
  font-family: "Mulish-ExtraBold";
  padding-bottom: 5px;
}

.top-header-content p {
  font-size: 15px;
  font-family: "Mulish-Regular";
  padding-left: 10px;
}

.col-icon {
  background-color: #ff4100;
  border-radius: 50%;
  padding: 5px;
}

label {
  width: 100% !important;
}

@media (max-width: 600px) {
  .content-container {
    margin: 0px 20px;
    padding: 25px 10px;
    margin-top: -42px;
    background-color: #ffffff;
    margin-bottom: 30px;
  }

  .main-container {
    overflow: auto;
  }

  .Login-heading p {
    font-size: 15px;
    font-family: "Mulish-Regular";
    line-height: 20px;
    padding-bottom: 4px;
    text-align: left;
  }

  .businessentity-container {
    display: flex;
    align-items: flex-start;
    margin-top: 8px;
  }

  select {
    background-position-x: 94%;
  }

  .icon-heading {
    align-items: flex-start;
  }

  .form-input label {
    font-size: 14px !important;
  }

  .icon-heading p {
    font-size: 14px;
  }

  .modal-dialog {
    min-width: auto !important;
    max-width: auto !important;
  }

  input[type="checkbox"],
  input[type="radio"] {
    width: 20px;
    height: 18px;
  }
  .top-header-content p {
    width: 100%;
  }
}
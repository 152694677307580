.custominput-container {
  .form-input {
    padding-bottom: 15px;
    width: 100%;
  }

  .form-input label {
    font-size: 16px;
    color: #000000;
    line-height: 28px;
    font-family: "Mulish-SemiBold";
  }

  .form-input input {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #dfdede;
    background-color: #ffffff;
    padding: 10px 12px;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    display: block;
    font-family: "Mulish-Light";
    height: 45px;
  }
  .form-input input:focus {
    outline: none;
  }
  .verbiage_content {
    font-size: 11px !important;
    color: #666666 !important;
    line-height: 18px !important;
    font-family: "Mulish-Regular" !important;
    display: block;
    margin-top: -8px;
  }
}

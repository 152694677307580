// .waitingLoader-container {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     height: 60vh;
//     flex-direction: column;
// }

.Esign-container p {
    text-align: center;
    // font: normal normal 600 14px/18px Muli;
    letter-spacing: 0px;
    font-family:'Mulish-SemiBold';
    color: #000000;
    opacity: 1;
    font-size: 16px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 20px;
}

.waiting-loader {
    width: 100px;
    /* control the size */
    aspect-ratio: 1;
    display: grid;
    -webkit-mask: conic-gradient(from 22deg, #0003, #000);
    mask: conic-gradient(from 22deg, #0003, #000);
    animation: load 1s steps(8) infinite;
}

.waiting-loader,
.waiting-loader:before {
    --_g: linear-gradient(#18AE68 0 0) 50%;
    /* update the color here */
    background:
        var(--_g)/34% 8% space no-repeat,
        var(--_g)/8% 34% no-repeat space;
}

.waiting-loader:before {
    content: "";
    transform: rotate(45deg);
}

.loader-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 0px;
}

@keyframes load {
    from {
        transform: rotate(0turn)
    }

    to {
        transform: rotate(1turn)
    }
}

@media(max-width:600px){
    .loader-box p {
        padding-left: 0px!important; 
        padding-right: 0px!important;
    }
}
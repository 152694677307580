
.address-modal .modal-body{
    width: 486px;
}

.address-container {
    background-color: white;
    border-radius: 10px;
}

.address-view {
    padding: 20px;
    padding-top: 0;
}

.address_modal_heading_box {
    background-color: #E35627;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    text-align: center;
}

.address_modal_heading_box h2{
    font-size: 17px;
    font-family: 'Mulish-Light';
    color: white;
    padding: 15px 0;
    letter-spacing: 0.56px;
}
.address-form-group{
    margin-top: 15px;
}
.address-form-group label{
    font-size: 16px;
    font-family: 'Mulish-SemiBold'
}
.address-form-group input{
    font-size: 14px;
    width: 100%;
    border-radius: 5px;
    border: none;
    border: 1px solid #F4F4F4;
    padding: 4px 10px;
    font-family: 'Mulish-Light';
    height: 45px;
}
:focus-visible {
    outline: -webkit-focus-ring-color auto 0px;
}
.address-form-group select{
    font-size: 14px;
    width: 100%;
    border-radius: 5px;
    border: none;
    border: 1px solid #F4F4F4;
    padding: 4px 10px;
    font-family: 'Mulish-Light';
    height: 45px;
}


.form-input {
    padding-bottom: 15px;
    width: 100%;
}

.form-input label {
    font-size: 16px;
    color: #000000;
    line-height: 28px;
    font-family: 'Mulish-SemiBold';
}

.form-input select {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #F4F4F4;
    background-color: #FFFFFF;
    // padding: 10px 12px;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    display: block;
    font-family: 'Mulish-Light';
}

.form-input select:focus {
    outline: none !important;
    box-shadow: none;
}

.form-input select:focus-visible {
    outline: none;
}



.file>input[type='file'] {
    display: none
}

.file>label {
    font-size: 1rem;
    font-weight: 300;
    cursor: pointer;
    width: 100% !important;
    border-radius: 5px;
    border: 1px dashed #707070 !important;
    background-color: #FFFFFF;
    padding: 10px 12px;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    display: block;
    font-family: 'Mulish-Light';
    height: auto !important;
    display: block !important;
}

.Clip-img {
    width: 18px;
    height: auto;
    transform: rotatey(180deg);
}

.file label span{
    float: right;
}
.fade .modal .show{
    padding-left: 0px !important;
}
.modal-dialog {
    min-width: 486px;
    max-width: 486px !important;
}

.cross_icon_container {
    background: #fff;
    width: 26px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    position: absolute;
    top: 4px;
    right: 8px;
}


@media screen and (max-width: 600px) {
    .address-modal .modal-body {
        max-width: 358px;
    }
}
.term-container {
  .contant-view {
    padding-left: 0px;
    padding-right: 0px;
  }

  .heading_view {
    font-size: 16px;
    font-family: "Mulish-ExtraBold";
    line-height: 20px;
    padding-bottom: 20px;
    color: #000000;
    text-align: center;
  }

  .term-heading {
    font-size: 22px;
    font-family: "Mulish-Bold";
    padding-bottom: 15px;
    padding-top: 15px;
  }

  .signing_at {
    font-size: 12px;
    font-family: "Mulish-Regular";
    line-height: 18px;
    color: #575757;
    padding-top: 6px;
  }

  .btn-main-container {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 18px;
  }

  .btn-view {
    background-color: #e35626;
    color: #ffffff;
    line-height: 18px;
    border: none;
    font-size: 16px;
    padding: 14px 35px;
    box-shadow: 0px 0px 10px #0000003d;
    border-radius: 7px;
    font-family: "Mulish-SemiBold";
    display: block;
    width: 200px;
    margin: auto;
  }

  .TermsAndCondition_box {
    border: 1px solid #cfcfcf;
    padding: 20px 10px;
    border-radius: 10px;
    height: 452px;
    overflow: auto;
  }

  .TermsAndCondition_box::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
    border-radius: 10px;
  }

  .TermsAndCondition_box::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
  }

  .TermsAndCondition_box::-webkit-scrollbar-thumb {
    background-color: #707070;
    border-radius: 10px;
  }

  p.term-text {
    font-size: 15px;
    font-family: "Mulish-Light";
    text-align: justify;
    line-height: 26px;
    padding-top: 10px;
    padding-bottom: 25px;
  }

  li {
    text-align: justify;
    line-height: 20px;
    margin-bottom: 15px;
    color: #000;
  }

  .term-subheading {
    font-size: 18px;
    font-family: "Mulish-Bold";
    padding-top: 15px;
    padding-bottom: 15px;
  }

  @media screen and (max-width: 600px) {
    .term-heading {
      font-size: 18px;
      padding-bottom: 8px;
      padding-top: 8px;
      text-align: left;
    }
    .term-subheading{
      font-size: 16px;
      padding-bottom: 8px;
      padding-top: 8px;
      text-align: left;
    }
    .term-container li{
      margin-bottom: 10px;
      line-height: 18px;
    }
    .btn-view {
      padding: 14px 10px;
      width: 210px;
     
    }
  }
  
}
.Address-container {
    .Address_View {
        width: 100%;
        min-height: 86vh;
        height: 86vh;
        background-color: #FFFFFF;
        position: relative;
    }


    .Address_body {
        padding-left: 18px;
        padding-right: 18px;
        margin-top: 22px;
    }

    .top-header {
        margin-bottom: 10px;
    }

    .top-header-icon {
        margin-right: 20px;
    }

    .top-header-icon img {
        width: 40px;
        height: auto;
    }

    .top-header-content h2 {
        font-size: 20px;
        color: #000000;
        line-height: 25px;
        font-family: 'Mulish-SemiBold';
    }

    .reject-img {
        width: 88px;
        height: auto;
        margin-bottom: 20px;
    }

    .Address_body h1 {
        font-size: 14px;
        font-family: 'Mulish-SemiBold';
        line-height: 18px;
        padding-bottom: 10px;
        color: #000000;
    }

    .Address_body p {
        font-size: 12px;
        font-family: 'Mulish-Light' !important;
        line-height: 18px;
        color: #000000;
        text-align: center;
    }

    .udyam_add_label {
        font-size: 14px;
        font-family: 'Mulish-Regular'
    }

    .udyam_radio_container p {
        text-align: left;
        font-size: 14px;
        font-family: 'Mulish-Regular';
        line-height: 22px;
    }

    .udyam_radio_container input {
        margin-right: 10px;
        accent-color: #FF4100;

    }

    .udyam_radio_container input:checked~label {
        color: #FF4100;
    }

    .udyam_radio_container label {
        font-size: 12px;
        color: #9B9B9B;
        line-height: 15px;
        font-family: 'Mulish-Regular';
    }

    .address-box {
        display: flex;
        align-items: center;
        background: #FFFFFF;
        border: 1px solid #DEDEDE;
        border-radius: 5px;
        padding: 8px 15px;
        margin-bottom: 20px;
        height: 45px;
    }
    
    .address_text {
        margin: 10px 0;
    }

    .none_check {
        font-size: 14px !important;
        color: #9B9B9B !important;
        font-family: 'Mulish-Regular' !important;
    }

    .submit-btn {
        padding: 20px 0px;
        text-align: center;
    }

    .proceed-btn {
        padding: 20px 0px;
        text-align: center;
    }

    .btn-view {
        border-radius: 5px;
        width: 150px;
        height: 40px;
        background-color: #E35626;
        color: #FFFFFF;
        line-height: 18px;
        border: none;
        font-size: 14px;
    }

    .proceed-btn .proceed-btn-view {
        border-radius: 5px;
        width: 130px;
        height: 40px;
        background-color: #E35626;
        color: #FFFFFF;
        line-height: 18px;
        border: none;
        font-size: 16px;
        margin-top: 10px;
    }

    .address_text .Address_type {
        font-family: "Mulish-Bold" !important;
        font-size: 16px;
    }

    .found_cancle_img {
        height: 88px;
        margin-right: 8px;
        margin-bottom: 25px;
    }

    .found_text_box {
        padding: 5px 10px;
        text-align: center;
        width: 32%;
    }

    .address_found_text {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .radio-container {
        margin: 15px 0px;
        display: flex;
        align-items: center;
    }

    .radio-container input {
        margin-right: 10px;
        accent-color: #FF4100;
    }

    .radio-container label {
        font-size: 12px;
        color: #000000;
        line-height: 15px;
        font-family: 'Mulish-Light';
        margin-bottom: 0px;
    }

    .address_found_text .Address_type1 {
        font-family: 'Mulish-SemiBold' !important;
        font-size: 16px !important;
        text-align: center !important;
        line-height: 30px;
        padding: 0px 2px;
    }

    .top-header-content {
        padding: 20px 0px 15px;
    }

    .Noaddress_text {
        margin-bottom: 20px;
    }
    .Noaddress_text {
        border: 1px solid #E35626;
        width: 146px;
        text-align: center;
        padding: 4px 12px;
        border-radius: 6px;
        color: #E35626;
    }
    .NoAddress_type{
        text-align: left;
        font-size: 14px;
        color: #E35626;
    }

     .found_text_box {
        padding: 5px 10px;
        text-align: center;
        width: 100%;
    }


    @media(max-width:600px){
       .address-box {
            align-items: flex-start;
            height: auto;
        }

        .Noaddress_text {
            width: 180px;
            padding: 4px 8px;
        
        }
        .NoAddress_type {
            text-align:center !important;
        }
    }
   
   

}
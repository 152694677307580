.VerifyOTP-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.VerifyOTP-view {
    background-color: #ffffff;
    width: 486px;
    border-radius: 10px;
    position: relative;
}

.modal-content {
    background-color: transparent !important;
    border: 0px;
}

.close-icon-container {
    position: absolute;
    right: 6px;
    top: 0px;
}

.close-icon {
    width: 14px;
    height: 14px;
    cursor: pointer;
}

.modal-content {
    border: none !important;
}

.VerifyOTP-modal-header {
    background-color: #E35627;
    text-align: center;
    padding: 16px 0px;
    border-radius: 10px 10px 0px 0px;
}

.VerifyOTP-modal-header p {
    font-size: 18px;
    color: #FFFFFF;
    line-height: 19px;
    font-family: 'Mulish-Regular';
}

.VerifyOTP-filed {
    padding: 18px 20px;
}

.VerifyOTP-message {
    text-align: center;
    // margin-bottom: 10px;
}

.VerifyOTP-message p {
    font-size: 16px;
    color: #000000;
    font-family: 'Mulish-SemiBold';
}

.VerifyOTP-message span {
    color: #E35626;
    font-size: 15px;
    padding-top: 8px;
}

.otp-field {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #F4F4F4;
    background-color: #FFFFFF;
    padding: 10px 12px;
    font-size: 14px;
    line-height: 18px;
    color: #707070;
    display: block;
}
.otp-field:focus-visible {
    outline: none;
}
.btn-container{
    text-align: center;
    margin-top: 20px;
}
.custom-btn-style{
    background-color: transparent !important;
    color: #FF4100;
    padding: 0px !important;
    border: 0px;
    display: block;
    margin: 12px auto; 
    font-size: 14px;
    font-family: 'Mulish-Regular';
}
.modal, .modal-backdrop{
    z-index: 999 !important;
}
.input-box{
    width: 32px;
    border-radius: 5px;
    border: 1px solid #cdcdcd;
    background-color: #FFFFFF;
    height: 30px;
    padding: 2px;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #000000;
    font-family: "Mulish-Light";
    margin: 20px 6px;
}
.input-box:focus{
    outline: none;
}
.close-icon-top{
    position: absolute;
    top: -34px;
    right: 12px;
}

.crossimg_container{
    background: #fff;
    width: 26px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    position: absolute;
    top: -9px;
    right: -7px;
}

.crossimg-img{
    width: 22px;
    height: auto;
    cursor: pointer;
}


.timer_view{
    font-size: 12px;
    padding-top: 7px;
    font-family: "Mulish-Regular";
}
p.consent_submit {
    font-size: 14px;
    text-align: center;
}

@media(max-width:600px){
    p.consent_submit {
        font-size: 14px;
        text-align: center;
    }
}
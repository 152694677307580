.TermsAndCondition-container {
  .TermsAndCondition_View {
    width: 100%;
    min-height: 88vh;
    background-color: #ffffff;
    position: relative;
  }
  .TermsAndCondition_body {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    flex-direction: column;
    padding-top: 12px;
  }
  .contant-view {
    padding: 27px 14px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 5px;
    padding-bottom: 1px !important;
  }
  .heading_view {
    font-size: 16px;
    font-family: "Mulish-ExtraBold";
    line-height: 20px;
    padding-bottom: 20px;
    color: #000000;
    text-align: center;
  }
  .content_style {
    font-size: 14px;
    font-family: "Mulish-Regular";
    line-height: 26px;
    color: #000000;
    text-align: justify;
  }
  .signing_at {
    font-size: 12px;
    font-family: "Mulish-Regular";
    line-height: 18px;
    color: #575757;
    padding-top: 6px;
  }
  .btn-main-container {
    display: flex;
    justify-content: center;
    margin-top: 18px;
    margin-bottom: 18px;
    margin-left: -8px;
    margin-right: -8px;
  }

  .btn-view {
    border-radius: 5px;
    // width: 107px;
    height: 40px;
    background-color: #e35626;
    color: #ffffff;
    line-height: 18px;
    border: none;
    font-size: 14px;
    padding: 0px 18px;
  }
  nav {
    width: 100%;
    background-color: #f5f5f5;
    border-radius: 100px;
  }
  .nav-tabs {
    border-bottom: none !important;
    justify-content: space-between;
    flex-wrap: nowrap;
  }
  .nav-tabs .nav-link {
    text-align: center;
    color: #000000;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0px;
    border: none !important;
    cursor: pointer;
  }
  .nav-tabs .nav-link.active {
    color: #495057;
    background: #e35626 0% 0% no-repeat padding-box;
    border-radius: 100px;
    color: #ffffff;
    text-align: center;
    padding-left: 30px;
    padding-right: 30px;
  }
  .nav-tabs .nav-link:hover {
    border: none !important;
  }

  .nav-link {
    display: block;
    padding: 12px 30px;
  }

  @media (max-width: 600px) {
    .nav-tabs {
      border-bottom: none !important;
      justify-content: center;
      flex-wrap: wrap;
    }

    nav {
      border-radius: 25px;
    }

    .nav-tabs .nav-link.active {
      width: 100%;
      padding-left: 10px;
      padding-right: 10px;
    }

    .nav-link {
      display: block;
      padding: 12px 10px;
    }

    .nav-tabs .nav-link {
      text-align: center;
      color: #000000;
      font-size: 14px;
      line-height: 28px;
      letter-spacing: 0px;
      border: none !important;
      cursor: pointer;
    }

    .nav-link {
      display: block;
      padding: 12px 10px;
    }

    .contant-view {
      padding: 25px 0px;
    }

    .content_style {
      line-height: 22px;
    }
  }
}

.SpokesLoader-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.SpokesLoader-container p{
    font-size: 14px;
    color: whitesmoke;
    line-height: 18px;
    font-family: 'Mulish-Regular';
    margin-top: 8px !important;
}

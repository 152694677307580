.ManuallyBankDetails-container {
  .ManuallyBankDetails_View {
    width: 100%;
    min-height: 88vh;
    background-color: #ffffff;
    position: relative;
  }

  .ManuallyBankDetails_body {
    padding-left: 18px;
    padding-right: 18px;
    margin-top: 22px;
  }

  .top-header {
    margin-bottom: 20px;
  }

  .top-header-icon {
    margin-right: 20px;
  }

  .top-header-icon img {
    width: 40px;
    height: auto;
  }

  .top-header-content h2 {
    font-size: 20px;
    color: #000000;
    line-height: 25px;
    font-family: "Mulish-SemiBold";
  }

  .top-header-content p {
    font-size: 12px;
    color: #000000;
    line-height: 18px;
    font-family: "Mulish-Light";
  }

  .form-body {}

  .form-body label {
    width: 100%;
  }

  .file-upload-label {
    font-size: 14px;
    color: #000000;
    line-height: 18px;
    font-family: "Mulish-SemiBold";
    padding-bottom: 10px;
  }

  .bankfile-upload {
    border: 1px dashed #707070;
    border-spacing: 4px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 9px 12px;
    height: 45px;
  }

  .paperclip-img {
    width: 18px;
    height: auto;
    transform: rotatey(180deg);
  }

  .bankfile-upload-left {
    display: flex;
    align-items: center;
  }

  .bankfile-upload p {
    font-size: 14px;
    color: #000000;
    line-height: 15px;
    font-family: "Mulish-Regular";
    padding-left: 8px;
  }

  .or-view {
    padding: 10px 0px;
  }

  .or-view p {
    font-size: 14px;
    color: #ff4100;
    line-height: 26px;
    font-family: "Mulish-Light";
    text-align: center;
  }

  .form-input {
    padding-bottom: 15px;
    width: 100%;
    padding-top: 15px;
  }

  .form-input label {
    font-size: 16px;
    color: #000000;
    line-height: 28px;
    font-family: "Mulish-SemiBold";
  }

  .form-input select {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #f4f4f4;
    background-color: #ffffff;
    // padding: 10px 12px;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    display: block;
    font-family: "Mulish-Light";
  }

  .form-input select:focus {
    outline: none !important;
    box-shadow: none;
  }

  .form-input select:focus-visible {
    outline: none;
  }

  .btn-main-container {
    display: flex;
    justify-content: center;
  }

  .btn-view {
    border-radius: 5px;
    width: 150px;
    height: 40px;
    background-color: #e35626;
    color: #ffffff;
    line-height: 18px;
    border: none;
    font-size: 14px;
  }

  .form-input .input-field {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #dfdede;
    background-color: #ffffff;
    padding: 10px 12px;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    display: block;
    font-family: "Mulish-Light";
    height: 45px;
  }

  .form-input .input-field:focus {
    outline: none;
  }

  .alert-content {
    font-size: 12px;
    line-height: 15px;
    color: #000000;
    display: block;
    font-family: "Mulish-SemiBold";
    margin-top: -8px;
    padding-bottom: 10px;
  }

  .form-body {
    margin-top: 15px;
  }

  select {
    background-position-x: 99%;
  }

  @media (max-width: 600px) {
    .or-view p {
      font-size: 12px;
    }

    select {
      background-position-x: 96%;
    }

    .bankfile-upload-left {
      width: 95%;
    }
    .bankfile-upload p {
  
      width: 92%;
  }
  }
}
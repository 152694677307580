.rm-container {
  body {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
  }

  .address-container {
    background-color: white;
    border-radius: 10px;
  }

  .address-view {
    padding: 20px;
    text-align: center;
  }

  img.reject-img {
    width: 140px;
    margin-left: 40px;
    margin-top: 20px;
  }

  p.rptext {
    font-size: 16px;
    font-family: "Mulish-Regular";
  }

  .address_modal_heading_box {
    background-color: #e35627;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    text-align: center;
  }

  .address_modal_heading_box h2 {
    font-size: 17px;
    font-family: "Mulish-Light";
    color: white;
    padding: 15px 0;
    letter-spacing: 0.56px;
  }
  .address-form-group {
    margin-top: 15px;
  }
  .address-form-group label {
    font-size: 16px;
    font-family: 'Mulish-SemiBold'
  }
  .address-form-group input {
    font-size: 14px;
    width: 100%;
    border-radius: 5px;
    border: none;
    border: 1px solid #f4f4f4;
    padding: 4px 10px;
    font-family: "Mulish-Light";
    height: 45px;
  }
  :focus-visible {
    outline: -webkit-focus-ring-color auto 0px;
  }
  .address-form-group select {
    font-size: 14px;
    width: 100%;
    border-radius: 5px;
    border: none;
    border: 1px solid #f4f4f4;
    padding: 4px 10px;
    font-family: "Mulish-Light";
  }

  .form-input {
    padding-bottom: 15px;
    width: 100%;
  }

  .form-input label {
    font-size: 16px;
    color: #000000;
    line-height: 28px;
    font-family: "Mulish-SemiBold";
  }

  .form-input select {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #f4f4f4;
    background-color: #ffffff;
    // padding: 10px 12px;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    display: block;
    font-family: "Mulish-Light";
  }

  .form-input select:focus {
    outline: none !important;
    box-shadow: none;
  }

  .form-input select:focus-visible {
    outline: none;
  }

  .file > input[type="file"] {
    display: none;
  }

  .file > label {
    font-size: 1rem;
    font-weight: 300;
    cursor: pointer;
    width: 100% !important;
    border-radius: 5px;
    border: 1px dashed #707070 !important;
    background-color: #ffffff;
    padding: 10px 12px;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    display: block;
    font-family: "Mulish-Light";
    height: auto !important;
    display: block !important;
  }

  .Clip-img {
    width: 18px;
    height: auto;
    transform: rotatey(180deg);
  }

  .file label span {
    float: right;
  }
  .fade .modal .show {
    padding-left: 0px !important;
  }
  .modal-dialog {
    min-width: 486px;
    max-width: 486px !important;
  }

  .modal-close-icon {
    width: 60px;
    float: right;
    text-align: center;
    margin-top: 10px;
    cursor: pointer;
  }

  p.rjclose-text {
    text-align: center;
    font-family: "Mulish-Regular";
    font-size: 12px;
  }

  img.cancle {
    width: 32px;
    background-color: #e4e4e4;
    padding: 9px;
    border-radius: 50%;
    margin-bottom: 2px;
  }

  .rpheading {
    font-size: 20px;
    font-family: "Mulish-Bold";
    padding-bottom: 10px;
  }

  @media (max-width: 600px) {
    .modal-dialog {
      min-width: auto;
      max-width: auto !important;
    }

    img.cancle {
      width: 36px;
    }

    p.rjclose-text {
      font-size: 15px;
    }

    .modal-close-icon {
      width: 70px;
    }

    img.reject-img {
      margin-left: 50px;
    }
  }
}
